import React, { useContext, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { getUserProfileFromLocalStorage } from '../services/userProfile';
import { submitVocabTestResults } from '../services/l2Api';
import { AnimatePresence, motion } from 'framer-motion';
import { VocabTestContext } from '../VocabRoutes';
import { VocabTestStatus } from '../utils/models';
import { GliteMotionButton } from '../components/GliteButton';
import { AppPages } from '../utils/pages';
import { useVocabStage } from '../services/vocabTest';

interface ProgressSplash {
  title: string;
  message: string;
}

export default function VocabTestScreen() {
  const [showProgressSplash, setShowProgressSplash] = useState(false);
  const [wordIndexChangeTime, setWordIndexChangeTime] = useState<Date | undefined>();
  const [direction, setDirection] = useState('forward');
  const { vocabTest, setVocabTest } = useContext(VocabTestContext)!;
  const userProfile = getUserProfileFromLocalStorage();
  useVocabStage(AppPages.VocabWords, userProfile, vocabTest);
  const { index } = useParams();
  const navigate = useNavigate();

  function wordIndex() {
    if (index && vocabTest && vocabTest.words) {
      const wordIndex = parseInt(index);
      if (wordIndex < 0 || wordIndex >= vocabTest!.words.length) {
        return 0;
      }
      return wordIndex;
    }
    return 0;
  }

  useEffect(() => {
    if (!vocabTest || !vocabTest.words) return;

    // Handle cases where the user navigates to a specific word
    // from a splash page, or uses the back button
    if (isWordIndexASplash(wordIndex())) {
      setShowProgressSplash(true);
    } else {
      setShowProgressSplash(false);
    }

    // Resume test if it was interrupted
    for (let i = 0; i < vocabTest!.words.length; i++) {
      if (vocabTest!.words[i].checked !== null) {
        navigate(AppPages.VocabWords.url + `/${i}`);
      } else {
        break;
      }
    }
  }, []);

  useEffect(() => {
    setWordIndexChangeTime(new Date());

    // Submit test results every 10 words, and at the end of the test
    if (wordIndex() > 1 && wordIndex() % 25 === 0) {
      submitPartialResults();
    }
  }, [wordIndex()]);

  if (!vocabTest || !vocabTest.words) return;
  const numberOfWords = vocabTest!.words.length;
  const wordsPerSection = 50;
  const currentSection = Math.floor(wordIndex() / wordsPerSection);
  const numberOfSections = Math.ceil(numberOfWords / wordsPerSection);
  const completedWordCountForSection = wordIndex() % wordsPerSection;
  const wordsRemainingInSection = wordsPerSection - completedWordCountForSection;

  const sectionName = () => {
    switch (currentSection) {
      case 0:
        // Must a string with a space to keep the layout consistent
        return ' ';
      case numberOfSections - 1:
        return 'Final Part';
      default:
        return `Part ${currentSection + 1}`;
    }
  };

  const progressSplash = (): ProgressSplash => {
    switch (currentSection) {
      case 1:
        return {
          title: 'Great progress!',
          message:
            "You're almost finished! Just 2 more sections to go so we can give you interesting insights about your English.",
        };
      case 2:
        return {
          title: 'Almost there!',
          message: "Just one more section to go! You're doing great, keep it up!",
        };
      default:
        return {
          title: 'Another section done!',
          message: "Keep going - you're doing great!",
        };
    }
  };

  const submitPartialResults = () => {
    submitVocabTestResults(vocabTest!).then(() => {});
  };

  const goBackOneWord = () => {
    if (wordIndex() > 0) {
      navigate(AppPages.VocabWords.url + `/${wordIndex() - 1}`);
    }
  };

  const isWordIndexASplash = (index: number) => {
    return index % wordsPerSection === 0 && index !== 0;
  };

  const saveWordKnownStatus = (known: boolean) => {
    const nextWordIndex = wordIndex() + 1;

    setVocabTest((oldTest) => {
      const newTest = { ...oldTest! };
      newTest.words[wordIndex()].checked = known;
      newTest.status = VocabTestStatus.IN_PROGRESS;

      // Calculate the time taken to make a decision
      if (wordIndexChangeTime) {
        newTest.words[wordIndex()].decision_time =
          new Date().getTime() - wordIndexChangeTime.getTime();
      }
      return newTest;
    });

    if (nextWordIndex >= numberOfWords) {
      // All words have been checked - test is complete - submit and navigate to email form
      submitPartialResults();
      navigate(AppPages.VocabEmail.url);
    } else {
      // Show progress splash every 50 words
      if (isWordIndexASplash(nextWordIndex)) {
        setShowProgressSplash(true);
      }

      // Move to the next word
      navigate(AppPages.VocabWords.url + `/${nextWordIndex}`);
    }
  };

  const forwardAnimationVariants = {
    initial: { opacity: 0, x: 150 }, // enter from the right
    in: { opacity: 1, x: 0 }, // center position
    out: { opacity: 0, x: -150 }, // exit to the left
  };

  const backwardAnimationVariants = {
    initial: { opacity: 0, x: -150 }, // enter from the left
    in: { opacity: 1, x: 0 }, // center position
    out: { opacity: 0, x: 150 }, // exit to the right
  };

  const animationVariants =
    direction === 'forward' ? forwardAnimationVariants : backwardAnimationVariants;

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.3,
  };

  return (
    <main>
      {showProgressSplash ? (
        <div className="container h-full md:max-h-[600px] my-10">
          <h1 className="title-lg">{progressSplash().title}</h1>
          <p>{progressSplash().message}</p>

          <button
            className="btn mt-2"
            onClick={() => {
              setWordIndexChangeTime(new Date());
              setShowProgressSplash(false);
              setDirection('forward');
            }}
          >
            Continue
          </button>
        </div>
      ) : (
        <div className="container h-full md:max-h-[600px] justify-between">
          <div className="flex flex-col w-full gap-[4px]">
            <div className="text-center title-4 text-black/60">{sectionName()}</div>
            <div className="flex justify-between items-center w-full min-h-[25px] relative">
              <div
                onClick={() => {
                  setDirection('backward');
                  goBackOneWord();
                }}
                className={`flex items-center -mx-1.5 cursor-pointer ${wordIndex() > 0 ? 'inline' : 'opacity-0'}`}
                onMouseEnter={() => {
                  setDirection('backward');
                }}
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#11111199"
                    d="M9 13C9 12.855 9.02788 12.7211 9.08363 12.5983C9.13939 12.4756 9.22581 12.3556 9.34289 12.2385L14.3525 7.27615C14.5364 7.09205 14.7595 7 15.0215 7C15.2947 7 15.5261 7.09205 15.7157 7.27615C15.9052 7.46583 16 7.69456 16 7.96234C16 8.23013 15.8969 8.46722 15.6906 8.67364L11.2999 13L15.6906 17.3264C15.8969 17.5328 16 17.7699 16 18.0377C16 18.3054 15.9052 18.5314 15.7157 18.7155C15.5261 18.9052 15.2947 19 15.0215 19C14.7595 19 14.5364 18.9079 14.3524 18.7238L9.34289 13.7615C9.1143 13.5439 9 13.2901 9 13Z"
                  />
                </svg>

                <span className="block title-5 text-black/60 leading-none">Back</span>
              </div>

              <span className="title-5 text-black/60 leading-none">
                {`${wordsRemainingInSection} word${wordsRemainingInSection > 1 ? 's' : ''} left`}
              </span>
            </div>
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{
                  width: `${(completedWordCountForSection / wordsPerSection) * 100}%`,
                }}
              ></div>
            </div>
          </div>

          <AnimatePresence mode="popLayout">
            <motion.div
              className="title-lg capitalize"
              initial="initial"
              animate="in"
              exit="out"
              variants={animationVariants}
              key={wordIndex()}
              transition={pageTransition}
            >
              {vocabTest!.words[wordIndex()].word}
            </motion.div>
          </AnimatePresence>

          <div className="flex justify-between md:justify-center gap-[18px] w-full">
            {['backward', 'forward'].map((btnDir) => {
              return (
                <GliteMotionButton
                  key={btnDir}
                  className="w-[168px]"
                  whileTap={{ scale: 0.9 }}
                  onMouseEnter={() => {
                    setDirection('forward');
                  }}
                  onClick={() => {
                    saveWordKnownStatus(btnDir === 'forward');
                    setDirection('forward');
                  }}
                >
                  {btnDir === 'forward' ? 'Know' : 'Not sure'}
                </GliteMotionButton>
              );
            })}
          </div>
        </div>
      )}
    </main>
  );
}
