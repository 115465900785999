import {
  CEFRLevelEnum,
  EducationEnum,
  GenderEnum,
  OccupationEnum,
} from '../services/enums';

type Language = 'en';
type CEFRLevel = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2';

interface WordFrequencyInfo {
  index: number;
  frequency: number;
}

interface Word {
  word: string;
  fake: boolean;
  checked: boolean;
  decision_time?: number;
  frequency_info?: WordFrequencyInfo;
}

interface UserProfile {
  email: string | null;
  native_language: string | null;
  age_group: number | null;
  country_of_residence: string | null;
  education_level: EducationEnum | null;
  occupation: OccupationEnum | null;
  gender: GenderEnum | null;
  cefr_test_2_years: boolean | null;
  cefr_level_2_years: CEFRLevelEnum | null;
  full_name: string | null;
}

interface UserSurvey {
  test_id: string;
  satisfaction?: number | null;
  comment?: string | null;
}

interface VocabularySize {
  lemmas_number: number;
  level: CEFRLevel;
}

enum VocabTestStatus {
  NEW = 'new',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}

interface VocabTest {
  test_id: string;
  language: Language;
  corpus_version: number;
  status: VocabTestStatus;
  created_datetime: string;
  completed_datetime: string | null;
  words: Word[];
  user_profile: UserProfile | null;
  vocab_size: VocabularySize | null;
  post_survey: UserSurvey | null;
  test_version: number | null;
}

type Satisfaction = 1 | 2 | 3 | 4 | 5;

interface VocabTestSurvey {
  test_id: string;
  satisfaction: Satisfaction | null;
  comment: string | null;
}

export {
  Language,
  CEFRLevel,
  WordFrequencyInfo,
  Word,
  UserProfile,
  UserSurvey,
  VocabularySize,
  VocabTestStatus,
  VocabTest,
  VocabTestSurvey,
  Satisfaction,
};
