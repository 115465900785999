import { UserProfile } from '../utils/models';

export function getUserProfileFromLocalStorage(): UserProfile {
  const userProfile = localStorage.getItem('userProfile');

  if (!userProfile) {
    const userProfile: UserProfile = {
      email: null,
      native_language: null,
      age_group: null,
      country_of_residence: null,
      education_level: null,
      occupation: null,
      gender: null,
      cefr_level_2_years: null,
      cefr_test_2_years: null,
      full_name: null,
    };
    saveUserProfileToLocalStorage(userProfile);
    return userProfile;
  }

  return JSON.parse(userProfile) as UserProfile;
}

export function saveUserProfileToLocalStorage(userProfile: UserProfile): void {
  for (const key in userProfile) {
    // Do some basic cleaning of string values
    if (Object.prototype.hasOwnProperty.call(userProfile, key)) {
      const upKey = key as keyof UserProfile;
      const upValue = userProfile[upKey];

      if (typeof upValue === 'string') {
        (userProfile[upKey] as string) = (upValue as string)
          .replace(/\s{2,}/g, '')
          .trim();
      }

      if (userProfile[upKey] === '') {
        userProfile[upKey] = null;
      }
    }
  }

  console.debug('Saving user profile to local storage', userProfile);
  localStorage.setItem('userProfile', JSON.stringify(userProfile));
}
