import React, { useEffect, useRef, useState } from 'react';

const useDropdownPosition = (
  thresholdHeight: number = 200,
  ref?: React.RefObject<HTMLDivElement>,
) => {
  const [dropdownUp, setDropdownUp] = useState(false);
  const wrapperRef = ref || useRef<HTMLDivElement>(null);

  const updateDropdownPosition = () => {
    if (wrapperRef.current) {
      const inputRect = wrapperRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - inputRect.bottom;
      const spaceAbove = inputRect.top;

      if (spaceBelow < thresholdHeight && spaceAbove > spaceBelow) {
        setDropdownUp(true);
      } else {
        setDropdownUp(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDropdownPosition);
    updateDropdownPosition(); // Call initially

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, []);

  return { dropdownUp, wrapperRef };
};

export default useDropdownPosition;
