import React, { ButtonHTMLAttributes, forwardRef, useRef } from 'react';
import { motion } from 'framer-motion';

interface GliteButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  key?: string | number;
}

/** Button that uses the Glite design system and always re-renders on click */
export const GliteButton = forwardRef<HTMLButtonElement, GliteButtonProps>(
  ({ children, ...props }, ref) => {
    const internalKeyRef = useRef(0);

    const internalOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      internalKeyRef.current += 1;
      if (props.onClick) {
        props.onClick(e);
      }
    };

    return (
      <button
        key={internalKeyRef.current}
        ref={ref}
        onClick={internalOnClick}
        {...props}
        className={`btn ${props.className || ''}`}
      >
        {children}
      </button>
    );
  },
);

GliteButton.displayName = 'GliteButton';

export const GliteMotionButton = motion(GliteButton);
GliteMotionButton.displayName = 'GliteMotionButton';
