import React, { forwardRef } from 'react';
import { FieldError, FieldValues, Path } from 'react-hook-form';
import { useSelect } from 'downshift';
import {
  Label,
  SelectIcon,
  InputGroup,
  InputHelpAndErrors,
  InputWrapper,
} from './utils/forms';
import useDropdownPosition from './utils/dropdown';

interface SelectFieldProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  options: Record<string, string>;
  placeholder?: string;
  required?: boolean;
  errors?: FieldError;
  value?: string;
  onChange?: (value: string) => void;
}

const SelectField = forwardRef(
  <T extends FieldValues>(
    {
      label,
      name,
      options,
      errors,
      placeholder = 'Select an option',
      required = false,
      value = '',
      onChange,
    }: SelectFieldProps<T>,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const { dropdownUp, wrapperRef } = useDropdownPosition();

    const items = Object.entries(options);
    const selectedKey = value ? items.find(([, val]) => val === value)?.[0] : null;

    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
      selectedItem,
    } = useSelect({
      items: items.map(([key]) => key),
      selectedItem: selectedKey || null,
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          onChange?.(options[selectedItem]);
        }
      },
    });

    return (
      <InputGroup errors={errors}>
        <Label label={label} htmlFor={name} required={required} />
        <InputWrapper ref={wrapperRef}>
          <SelectIcon
            selected={!!selectedItem}
            onClick={(event) => {
              event.stopPropagation();
              onChange?.('');
            }}
          />
          <button
            name={name}
            type="button"
            aria-invalid={errors ? 'true' : 'false'}
            className={`input-field select ${isOpen ? 'open' : ''} ${dropdownUp ? 'above' : 'below'} ${selectedItem ? '' : 'placeholder'}`}
            {...getToggleButtonProps({ ref })}
          >
            {selectedItem ? options[selectedItem] : placeholder}
          </button>
          <ul
            className={`select-option-list ${isOpen ? 'open' : ''} ${dropdownUp ? 'above' : 'below'}`}
            {...getMenuProps()}
          >
            {isOpen &&
              items.map(([key, val], index) => (
                <li
                  key={key}
                  className={`select-option ${
                    highlightedIndex === index ? 'highlighted' : ''
                  }`}
                  {...getItemProps({ index, item: key })}
                >
                  {val}
                </li>
              ))}
          </ul>
        </InputWrapper>
        <InputHelpAndErrors errors={errors} />
      </InputGroup>
    );
  },
);

SelectField.displayName = 'SelectField';
export default SelectField;
