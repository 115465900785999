// noinspection SqlDialectInspection,SqlNoDataSourceInspection

import React, { useEffect, useState } from 'react';
import '../styles/main.css';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CountriesEnum, GenderEnum } from '../services/enums';
import ComboboxField from '../components/ComboboxField';
import SelectField from '../components/SelectField';
import { InputGroup, InputHelpAndErrors, Label } from '../components/utils/forms';
import { UserProfile } from '../utils/models';
import { AppPages } from '../utils/pages';
import { useNavigate } from 'react-router-dom';
import { submitVocabTestResults } from '../services/l2Api';
import {
  getUserProfileFromLocalStorage,
  saveUserProfileToLocalStorage,
} from '../services/userProfile';
import { useVocabTestState } from '../services/VocabTestProvider';
import { GliteButton } from '../components/GliteButton';
import { useVocabStage } from '../services/vocabTest';

interface IFormInput {
  full_name: string;
  email: string;
  gender: GenderEnum;
  country_of_residence: CountriesEnum;
}

export default function UserEmailForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { vocabTest, setVocabTest } = useVocabTestState();
  const userProfile = getUserProfileFromLocalStorage();
  useVocabStage(AppPages.VocabResults, userProfile, vocabTest);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>();

  useEffect(() => {
    const fields: (keyof UserProfile)[] = [
      'full_name',
      'email',
      'gender',
      'country_of_residence',
    ];

    fields.forEach((field) => {
      if (userProfile[field]) {
        setValue(field as keyof IFormInput, userProfile[field] as never);
      }
    });
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setLoading(true);

    userProfile.full_name = data.full_name;
    userProfile.email = data.email;
    userProfile.gender = data.gender;
    userProfile.country_of_residence = data.country_of_residence;
    saveUserProfileToLocalStorage(userProfile);

    const newVocabTest = { ...vocabTest!, user_profile: userProfile };
    setVocabTest(newVocabTest);

    submitVocabTestResults(newVocabTest, true)
      .then((data) => {
        setVocabTest(data);
        navigate(AppPages.VocabResults.url);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Failed to submit test results:', error);
        setError('Failed to submit the test results. Please try again.');
      });
  };

  return (
    <main>
      <div className="container">
        <h1 className="title-lg">Get your results!</h1>

        {error && <p className="text-accent-error">{error}</p>}

        <p>
          Just one more step to get your results! Enter the information below so we can
          personalize your feedback and make it more relevant to you.
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className={'mt-2'} id="emailForm">
          <Controller
            render={({ field }) => (
              <ComboboxField
                label="Country of residence"
                options={CountriesEnum}
                placeholder="Select a country"
                required={true}
                errors={errors.country_of_residence}
                autoComplete="country"
                {...field}
              />
            )}
            control={control}
            name="country_of_residence"
            rules={{
              required: 'This field is required',
              validate: (v: string) =>
                Object.values(CountriesEnum).includes(v as CountriesEnum) ||
                'Select a country from the list',
            }}
          />

          <InputGroup errors={errors.full_name}>
            <Label label={'Full name'} htmlFor={'full_name'} />

            <input
              {...register('full_name')}
              type="text"
              className="input-field replay-mask"
              autoComplete="name"
              placeholder="Your name"
              aria-invalid={errors.full_name ? 'true' : 'false'}
            />

            <InputHelpAndErrors errors={errors.full_name} />
          </InputGroup>

          <InputGroup errors={errors.email}>
            <Label label={'Email'} htmlFor={'email'} />

            <input
              {...register('email', {
                pattern: {
                  value: /(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$|^\S$)/i,
                  message: 'Enter a valid email address',
                },
              })}
              type="email"
              autoComplete="email"
              className="input-field replay-mask"
              placeholder="Your email"
              aria-invalid={errors.email ? 'true' : 'false'}
            />

            <InputHelpAndErrors errors={errors.email} />
          </InputGroup>

          <Controller
            render={({ field }) => (
              <SelectField
                label="Gender"
                options={GenderEnum}
                placeholder="Select a gender"
                errors={errors.gender}
                {...field}
              />
            )}
            control={control}
            name="gender"
            rules={{
              validate: (v: string) =>
                !v ||
                Object.values(GenderEnum).includes(v as GenderEnum) ||
                'Select a gender from the list',
            }}
          />

          <GliteButton type="submit" className={`btn mt-2`} disabled={loading}>
            {loading ? 'Loading...' : 'Get results'}
          </GliteButton>
        </form>
      </div>
    </main>
  );
}
