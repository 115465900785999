import { VocabTest } from '../utils/models';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as Sentry from '@sentry/react';

const DEFAULT_API_URL = 'https://api.glite.ai/l2';

enum EndpointsEnum {
  CheckboxTest = 'vocab_checkbox_tests',
  CheckboxTestSubmit = 'vocab_checkbox_tests/[test_id]',
}

const apiClient = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosRetry(apiClient, {
  retries: 10,
  retryDelay: axiosRetry.exponentialDelay,
  onRetry: () => {
    Sentry.captureMessage('Retrying request to Glite API');
  },
});

function getBaseURL(): string {
  return process.env.GLITE_API_URL || DEFAULT_API_URL;
}

function getEndpointPath(
  endpoint: EndpointsEnum,
  test_id: string = '',
  version: number = 1,
): string {
  let endpoint_str = endpoint as string;

  if (endpoint.includes('[test_id]')) {
    if (test_id === '') {
      throw new Error('test_id is required for this endpoint');
    }
    endpoint_str = endpoint_str.replace('[test_id]', test_id);
  }

  return `/v${version}/${endpoint_str}`;
}

// Global promise for fetching a new vocab test object to prevent duplicate requests
let vocabTestFetchPromise: Promise<VocabTest> | null = null;

/** Function to fetch a new vocab test object from the API, preventing duplicate requests */
export function fetchVocabTest(): Promise<VocabTest> {
  console.debug('fetchVocabTest: Invoked');
  if (!vocabTestFetchPromise) {
    console.debug('fetchVocabTest: No pending request, fetching new test');
    vocabTestFetchPromise = apiClient
      .post<VocabTest>(getEndpointPath(EndpointsEnum.CheckboxTest), {
        language: 'en',
      })
      .then((response) => {
        vocabTestFetchPromise = null;

        if (response.status === 201) {
          return response.data;
        } else {
          throw new Error('Failed to fetch vocab test');
        }
      })
      .catch((error) => {
        vocabTestFetchPromise = null;
        throw error;
      });
  }
  return vocabTestFetchPromise;
}

export async function submitVocabTestResults(
  vocabTest: VocabTest,
  complete: boolean = false,
): Promise<VocabTest> {
  let url = getEndpointPath(EndpointsEnum.CheckboxTestSubmit, vocabTest.test_id);

  if (complete) {
    url = `${url}?complete`;

    // Ensure all words have a decision_time value
    for (let i = 0; i < vocabTest.words.length; i++) {
      if (!vocabTest.words[i].decision_time) {
        vocabTest.words[i].decision_time = 0.0;
      }
    }
  }

  const response = await apiClient.patch<VocabTest>(url, vocabTest);

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(`Failed to submit ${complete ? 'complete' : 'partial'} test results.`);
}
