import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://247bfcf634999d6d9cc01907a9c63917@o4507072624984064.ingest.de.sentry.io/4507072628195408',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.contextLinesIntegration(),
    Sentry.dedupeIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.sessionTimingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      mask: ['.replay-mask'],
    }),
  ],

  /* Ignore errors from anonymous functions, such as those from Google Tag Manager */
  beforeSend(event) {
    try {
      if (
        event!.exception!.values![0].stacktrace!.frames![0].filename === `<anonymous>`
      ) {
        return null;
      }
    } catch (e) {
      /* ignore */
    }

    return event;
  },

  tracesSampleRate: 0.1,
  tracePropagationTargets: ['https://api.glite.ai/', /\/l2\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ['TypeError: Failed to fetch'],
  environment: process.env.ENV,
});

Sentry.setTag('app', 'glite-l2-tests');
