export function getEnumKeyByValue<T extends object>(
  enumObject: T,
  value: T[keyof T],
): string | undefined {
  return (Object.keys(enumObject) as Array<keyof T>).find(
    (key) => enumObject[key] === value,
  ) as string | undefined;
}

export enum CEFRLevelEnum {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export enum LanguagesEnum {
  afrikaans = 'Afrikaans',
  albanian = 'Albanian',
  amharic = 'Amharic',
  arabic = 'Arabic',
  armenian = 'Armenian',
  bengali = 'Bengali',
  bosnian = 'Bosnian',
  bulgarian = 'Bulgarian',
  cantonese = 'Cantonese',
  croatian = 'Croatian',
  czech = 'Czech',
  danish = 'Danish',
  dutch = 'Dutch',
  english = 'English',
  estonian = 'Estonian',
  finnish = 'Finnish',
  french = 'French',
  georgian = 'Georgian',
  german = 'German',
  greek = 'Greek',
  gujarati = 'Gujarati',
  hausa = 'Hausa',
  hebrew = 'Hebrew',
  hindi = 'Hindi',
  hungarian = 'Hungarian',
  icelandic = 'Icelandic',
  igbo = 'Igbo',
  indonesian = 'Indonesian',
  irish = 'Irish',
  italian = 'Italian',
  japanese = 'Japanese',
  javanese = 'Javanese',
  kannada = 'Kannada',
  kazakh = 'Kazakh',
  khmer = 'Khmer',
  korean = 'Korean',
  kurdish = 'Kurdish',
  lao = 'Lao',
  latvian = 'Latvian',
  lithuanian = 'Lithuanian',
  macedonian = 'Macedonian',
  malay = 'Malay',
  malayalam = 'Malayalam',
  marathi = 'Marathi',
  mongolian = 'Mongolian',
  nepali = 'Nepali',
  norwegian = 'Norwegian',
  pashto = 'Pashto',
  persian = 'Persian',
  polish = 'Polish',
  portuguese = 'Portuguese',
  punjabi = 'Punjabi',
  romanian = 'Romanian',
  russian = 'Russian',
  serbian = 'Serbian',
  sinhala = 'Sinhala',
  slovak = 'Slovak',
  slovenian = 'Slovenian',
  somali = 'Somali',
  spanish = 'Spanish',
  swahili = 'Swahili',
  swedish = 'Swedish',
  tagalog = 'Tagalog',
  tamil = 'Tamil',
  telugu = 'Telugu',
  thai = 'Thai',
  turkish = 'Turkish',
  uighur = 'Uighur',
  ukrainian = 'Ukrainian',
  urdu = 'Urdu',
  uzbek = 'Uzbek',
  vietnamese = 'Vietnamese',
  welsh = 'Welsh',
  xhosa = 'Xhosa',
  yoruba = 'Yoruba',
  zulu = 'Zulu',
}

export enum GenderEnum {
  male = 'Male',
  female = 'Female',
  nonBinary = 'Non-Binary/Genderfluid',
  preferNotToSay = 'Prefer not to say',
}

export enum EducationEnum {
  none = 'No Formal Education',
  primarySchool = 'Primary/Elementary School Completed',
  highSchool = 'Secondary/High School',
  collegeDegree = 'College Degree',
  bachelorsDegree = "Bachelor's Degree",
  mastersDegree = "Master's Degree",
  postgraduateStudies = 'Postgraduate Studies',
  doctorateDegree = 'Doctorate Degree',
}

export enum OccupationEnum {
  admin = 'Administration & Office Support',
  business = 'Business & Finance',
  education = 'Education & Training',
  healthcare = 'Healthcare',
  technology = 'Technology',
  sales = 'Sales',
  constructions = 'Construction',
  transportation = 'Transportation',
  food = 'Food & Service',
  production = 'Production',
  other = 'Other',
}

export enum CountriesEnum {
  AF = 'Afghanistan',
  AL = 'Albania',
  DZ = 'Algeria',
  AD = 'Andorra',
  AO = 'Angola',
  AG = 'Antigua and Barbuda',
  AR = 'Argentina',
  AM = 'Armenia',
  AU = 'Australia',
  AT = 'Austria',
  AZ = 'Azerbaijan',
  BS = 'Bahamas',
  BH = 'Bahrain',
  BD = 'Bangladesh',
  BB = 'Barbados',
  BY = 'Belarus',
  BE = 'Belgium',
  BZ = 'Belize',
  BJ = 'Benin',
  BT = 'Bhutan',
  BO = 'Bolivia',
  BA = 'Bosnia and Herzegovina',
  BW = 'Botswana',
  BR = 'Brazil',
  BN = 'Brunei',
  BG = 'Bulgaria',
  BF = 'Burkina Faso',
  BI = 'Burundi',
  CV = 'Cabo Verde',
  KH = 'Cambodia',
  CM = 'Cameroon',
  CA = 'Canada',
  CF = 'Central African Republic',
  TD = 'Chad',
  CL = 'Chile',
  CN = 'China',
  CO = 'Colombia',
  KM = 'Comoros',
  CG = 'Congo',
  CR = 'Costa Rica',
  HR = 'Croatia',
  CU = 'Cuba',
  CY = 'Cyprus',
  CZ = 'Czech Republic',
  DK = 'Denmark',
  DJ = 'Djibouti',
  DM = 'Dominica',
  DO = 'Dominican Republic',
  TL = 'East Timor',
  EC = 'Ecuador',
  EG = 'Egypt',
  SV = 'El Salvador',
  GQ = 'Equatorial Guinea',
  ER = 'Eritrea',
  EE = 'Estonia',
  SZ = 'Eswatini',
  ET = 'Ethiopia',
  FJ = 'Fiji',
  FI = 'Finland',
  FR = 'France',
  GA = 'Gabon',
  GM = 'Gambia',
  GE = 'Georgia',
  DE = 'Germany',
  GH = 'Ghana',
  GR = 'Greece',
  GD = 'Grenada',
  GT = 'Guatemala',
  GN = 'Guinea',
  GW = 'Guinea-Bissau',
  GY = 'Guyana',
  HT = 'Haiti',
  HN = 'Honduras',
  HU = 'Hungary',
  IS = 'Iceland',
  IN = 'India',
  ID = 'Indonesia',
  IR = 'Iran',
  IQ = 'Iraq',
  IE = 'Ireland',
  IL = 'Israel',
  IT = 'Italy',
  JM = 'Jamaica',
  JP = 'Japan',
  JO = 'Jordan',
  KZ = 'Kazakhstan',
  KE = 'Kenya',
  KI = 'Kiribati',
  KP = 'Korea, North',
  KR = 'Korea, South',
  XK = 'Kosovo',
  KW = 'Kuwait',
  KG = 'Kyrgyzstan',
  LA = 'Laos',
  LV = 'Latvia',
  LB = 'Lebanon',
  LS = 'Lesotho',
  LR = 'Liberia',
  LY = 'Libya',
  LI = 'Liechtenstein',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  MG = 'Madagascar',
  MW = 'Malawi',
  MY = 'Malaysia',
  MV = 'Maldives',
  ML = 'Mali',
  MT = 'Malta',
  MH = 'Marshall Islands',
  MR = 'Mauritania',
  MU = 'Mauritius',
  MX = 'Mexico',
  FM = 'Micronesia',
  MD = 'Moldova',
  MC = 'Monaco',
  MN = 'Mongolia',
  ME = 'Montenegro',
  MA = 'Morocco',
  MZ = 'Mozambique',
  MM = 'Myanmar',
  NA = 'Namibia',
  NR = 'Nauru',
  NP = 'Nepal',
  NL = 'Netherlands',
  NZ = 'New Zealand',
  NI = 'Nicaragua',
  NE = 'Niger',
  NG = 'Nigeria',
  MK = 'North Macedonia',
  NO = 'Norway',
  OM = 'Oman',
  PK = 'Pakistan',
  PW = 'Palau',
  PA = 'Panama',
  PG = 'Papua New Guinea',
  PY = 'Paraguay',
  PE = 'Peru',
  PH = 'Philippines',
  PL = 'Poland',
  PT = 'Portugal',
  QA = 'Qatar',
  RO = 'Romania',
  RU = 'Russia',
  RW = 'Rwanda',
  KN = 'Saint Kitts and Nevis',
  LC = 'Saint Lucia',
  VC = 'Saint Vincent and the Grenadines',
  WS = 'Samoa',
  SM = 'San Marino',
  ST = 'Sao Tome and Principe',
  SA = 'Saudi Arabia',
  SN = 'Senegal',
  RS = 'Serbia',
  SC = 'Seychelles',
  SL = 'Sierra Leone',
  SG = 'Singapore',
  SK = 'Slovakia',
  SI = 'Slovenia',
  SB = 'Solomon Islands',
  SO = 'Somalia',
  ZA = 'South Africa',
  SS = 'South Sudan',
  ES = 'Spain',
  LK = 'Sri Lanka',
  SD = 'Sudan',
  SR = 'Suriname',
  SE = 'Sweden',
  CH = 'Switzerland',
  SY = 'Syria',
  TW = 'Taiwan',
  TJ = 'Tajikistan',
  TZ = 'Tanzania',
  TH = 'Thailand',
  TG = 'Togo',
  TO = 'Tonga',
  TT = 'Trinidad and Tobago',
  TN = 'Tunisia',
  TR = 'Turkey',
  TM = 'Turkmenistan',
  TV = 'Tuvalu',
  UG = 'Uganda',
  UA = 'Ukraine',
  AE = 'United Arab Emirates',
  GB = 'United Kingdom',
  US = 'United States',
  UY = 'Uruguay',
  UZ = 'Uzbekistan',
  VU = 'Vanuatu',
  VA = 'Vatican City',
  VE = 'Venezuela',
  VN = 'Vietnam',
  YE = 'Yemen',
  ZM = 'Zambia',
  ZW = 'Zimbabwe',
}
