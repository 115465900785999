import React, { useEffect } from 'react';
import '../styles/main.css';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CEFRLevelEnum } from '../services/enums';
import SelectField from '../components/SelectField';
import { AppPages } from '../utils/pages';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getUserProfileFromLocalStorage,
  saveUserProfileToLocalStorage,
} from '../services/userProfile';
import { GliteButton } from '../components/GliteButton';
import { useVocabStage } from '../services/vocabTest';

export enum CEFRLevelFormEnum {
  NotTaken = 'I have not taken a test',
  Unknown = "I don't know my level",
  A1 = 'Level A1',
  A2 = 'Level A2',
  B1 = 'Level B1',
  B2 = 'Level B2',
  C1 = 'Level C1',
  C2 = 'Level C2',
}

interface IFormInput {
  cefr_level: CEFRLevelFormEnum;
}

export default function CEFRLevelForm() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>();
  const { level } = useParams();
  const navigate = useNavigate();
  const userProfile = getUserProfileFromLocalStorage();
  useVocabStage(AppPages.VocabCEFR, userProfile);

  // Sync user profile with form at the start
  useEffect(() => {
    if (userProfile.cefr_level_2_years) {
      userProfile.cefr_test_2_years = true;
      setValue(
        'cefr_level',
        CEFRLevelFormEnum[
          userProfile.cefr_level_2_years as keyof typeof CEFRLevelFormEnum
        ],
      );
    }
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (data.cefr_level) {
      if (data.cefr_level === CEFRLevelFormEnum.NotTaken) {
        userProfile.cefr_level_2_years = null;
        userProfile.cefr_test_2_years = false;
      } else {
        const cefr_level_key =
          Object.keys(CEFRLevelFormEnum)[
            Object.values(CEFRLevelFormEnum).indexOf(data.cefr_level)
          ];
        userProfile.cefr_level_2_years =
          CEFRLevelEnum[cefr_level_key as keyof typeof CEFRLevelEnum];
        userProfile.cefr_test_2_years = true;
      }
    } else {
      userProfile.cefr_level_2_years = null;
      userProfile.cefr_test_2_years = false;
    }

    saveUserProfileToLocalStorage(userProfile);
    navigate(AppPages.VocabInstructions.url);
  };

  function saveTestNotTaken() {
    userProfile.cefr_level_2_years = null;
    userProfile.cefr_test_2_years = false;
    saveUserProfileToLocalStorage(userProfile);
  }

  return (
    <main>
      <div className="container">
        {level ? (
          <div className="inner-container min-h-[370px] justify-between md:min-h-[350px] md:justify-between">
            <h1 className="title-lg mb-2">Your current level</h1>
            <p>What CEFR level did you achieve in your proficiency test?</p>
            <p>
              <strong>Important:</strong> The results should be from a test taken within
              the last 2 years.
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="my-3" id="cefrLevelForm">
              <Controller
                render={({ field }) => (
                  <SelectField
                    label={''}
                    options={CEFRLevelFormEnum}
                    placeholder={'CEFR Level'}
                    errors={errors.cefr_level}
                    required={false}
                    {...field}
                  />
                )}
                control={control}
                name="cefr_level"
                rules={{
                  validate: (v: string) =>
                    Object.values(CEFRLevelFormEnum).includes(v as CEFRLevelFormEnum) ||
                    'Select a valid CEFR level or go back',
                }}
              />

              <GliteButton className="w-full light mt-5" type="submit">
                Save CEFR Level
              </GliteButton>

              <GliteButton
                className="w-full"
                type="button"
                onClick={() => {
                  saveTestNotTaken();
                  navigate(AppPages.VocabInstructions.url);
                }}
              >
                I have not taken a test
              </GliteButton>
            </form>
          </div>
        ) : (
          <div className="inner-container min-h-[370px] justify-between md:min-h-[350px] md:justify-between">
            <h1 className="title-lg mb-2">Your current level</h1>
            <p>
              Knowing your current level of English proficiency will help to personalize
              the test.
            </p>
            <p>Have you taken an IELTS or TOEFL English language test recently?</p>
            <div className="mt-5 flex flex-col justify-between w-full gap-4">
              <GliteButton
                className="light"
                type="button"
                onClick={() => {
                  navigate(AppPages.VocabCEFR.url + '/level');
                }}
              >
                Yes, within the last 2 years
              </GliteButton>

              <GliteButton
                className="w-full"
                type="button"
                onClick={() => {
                  saveTestNotTaken();
                  navigate(AppPages.VocabInstructions.url);
                }}
              >
                No, continue to the test
              </GliteButton>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
