import React, { Fragment, ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/main.css';
import { AppPages } from '../utils/pages';
import { useVocabTestState } from '../services/VocabTestProvider';
import { getUserProfileFromLocalStorage } from '../services/userProfile';
import { GliteButton } from '../components/GliteButton';
import { useVocabStage } from '../services/vocabTest';

interface Instructions {
  stage: number;
  text: ReactElement;
  buttonText: string;
}

// Google Translate Bug Workaround
// https://issues.chromium.org/issues/41407169
// Span tags below are to prevent Google Translate from crashing React

const instructions: Instructions[] = [
  {
    stage: 0,
    text: (
      <div className="inner-container">
        <h1 className="title-lg mb-2">About the test</h1>

        <p>
          Tap the <strong>Know</strong> button if you know the word.
        </p>

        <p>If you can think of at least one meaning, you know it!</p>

        <p>You shouldn&apos;t spend too long on each word.</p>

        <p>
          If you are struggling, just tap <strong>Not sure</strong>.
        </p>
      </div>
    ),
    buttonText: 'Continue',
  },
  {
    stage: 1,

    // Fragment tag is *required* to prevent Google Translate from crashing React
    text: (
      <Fragment>
        <div className="inner-container">
          <h1 className="title-lg mb-2">Fake words</h1>

          <p>An extra challenge: not all words in the test are real English words.</p>

          <p>The fake words are there to keep you on your toes.</p>

          <p>Don&apos;t worry if there are some you don&apos;t recognize!</p>
        </div>
      </Fragment>
    ),
    buttonText: "Let's go! Start the test!",
  },
];

export default function VocabTestInstructionsScreen() {
  const { vocabTest } = useVocabTestState();
  const navigate = useNavigate();
  const userProfile = getUserProfileFromLocalStorage();
  const [loading, setLoading] = React.useState(false);
  const { index } = useParams();

  useVocabStage(AppPages.VocabInstructions, userProfile);

  const lastStageNumber = instructions.length - 1;
  const stageNumber = Number(index) || 0;
  const stageNumToRender = Math.min(stageNumber, lastStageNumber);

  const goForward = () => {
    if (stageNumber < lastStageNumber) {
      navigate(`${AppPages.VocabInstructions.url}/${stageNumber + 1}`);
    } else {
      setLoading(true);
    }
  };

  useEffect(() => {
    if (loading && vocabTest && vocabTest.words) {
      return navigate(AppPages.VocabWords.url);
    }
  }, [vocabTest, loading]);

  return (
    <main>
      <div className="container">
        <div className="inner-container">
          {instructions[stageNumToRender].text}
          <span className="hidden">Google Translate Bug Workaround - Do Not Remove</span>
        </div>

        <GliteButton className="mt-3" disabled={loading} onClick={goForward}>
          {loading ? 'Loading...' : instructions[stageNumToRender].buttonText}
          <span className="hidden">Google Translate Bug Workaround - Do Not Remove</span>
        </GliteButton>
      </div>
    </main>
  );
}
