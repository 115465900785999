import React, { ReactNode, useContext } from 'react';
import { VocabTest } from '../utils/models';
import { VocabTestContext } from '../VocabRoutes';

interface VocabTestProviderProps {
  children: ReactNode;
  vocabTest: VocabTest | null;
  setVocabTest: React.Dispatch<React.SetStateAction<VocabTest | null>>;
}

export default function VocabTestProvider({
  children,
  vocabTest,
  setVocabTest,
}: VocabTestProviderProps) {
  return (
    <VocabTestContext.Provider value={{ vocabTest, setVocabTest }}>
      {children}
    </VocabTestContext.Provider>
  );
}

export const useVocabTestState = () => {
  const context = useContext(VocabTestContext);
  if (!context) {
    throw new Error('useVocabTestState must be used within a VocabTestProvider');
  }
  return context;
};
