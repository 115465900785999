import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

export function SelectIcon({
  selected,
  onClick,
}: {
  selected: boolean;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}) {
  if (selected) {
    return (
      <svg
        className="input-icon"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2929 7.29289C16.6834 6.90237 17.3166 6.90237 17.7071 7.29289C18.0976 7.68342 18.0976 8.31658 17.7071 8.70711L13.9142 12.5L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L12.5 13.9142L8.70711 17.7071C8.31658 18.0976 7.68342 18.0976 7.29289 17.7071C6.90237 17.3166 6.90237 16.6834 7.29289 16.2929L11.0858 12.5L7.29289 8.70711C6.90237 8.31658 6.90237 7.68342 7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289L12.5 11.0858L16.2929 7.29289Z"
        />
      </svg>
    );
  }
  return (
    <svg
      className="input-icon"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
    >
      <path d="M12.5 16.5C12.355 16.5 12.2211 16.4721 12.0983 16.4164C11.9756 16.3606 11.8556 16.2742 11.7385 16.1571L6.77615 11.1476C6.59205 10.9636 6.5 10.7405 6.5 10.4785C6.5 10.2053 6.59205 9.97391 6.77615 9.78435C6.96583 9.59478 7.19456 9.5 7.46234 9.5C7.73013 9.5 7.96722 9.60315 8.17364 9.80944L12.5 14.2001L16.8264 9.80944C17.0328 9.60315 17.2699 9.5 17.5377 9.5C17.8054 9.5 18.0314 9.59478 18.2155 9.78435C18.4052 9.97391 18.5 10.2053 18.5 10.4785C18.5 10.7405 18.4079 10.9636 18.2238 11.1476L13.2615 16.1571C13.0439 16.3857 12.7901 16.5 12.5 16.5Z" />
    </svg>
  );
}

interface LabelProps {
  label: string;
  htmlFor: string;
  required?: boolean;
  errors?: FieldError;
}

export const Label: React.FC<LabelProps> = ({ label, htmlFor, required }) => (
  <label className="input-label" htmlFor={htmlFor}>
    {label} {required && <span className={'input-required'}>*</span>}
  </label>
);

interface FormWrapperProps {
  children: React.ReactNode;
  errors?: FieldError;
  ref?: React.RefObject<HTMLDivElement>;
}

export const InputGroup: React.FC<FormWrapperProps> = ({ children, errors }) => (
  <div className={`input-group group ${errors ? 'errors' : ''}`}>{children}</div>
);

export const InputWrapper = forwardRef<HTMLDivElement, FormWrapperProps>(
  ({ children }, ref) => (
    <div ref={ref} className={'input-wrapper'}>
      {children}
    </div>
  ),
);
InputWrapper.displayName = 'InputWrapper';

interface InputHelpAndErrorsProps {
  errors?: FieldError;
  helpText?: string;
}

export const InputHelpAndErrors: React.FC<InputHelpAndErrorsProps> = ({
  errors,
  helpText,
}) => (
  <>
    {(errors && <span className={'input-error'}>{errors.message}</span>) ||
      (helpText && <span className={'input-help'}>{helpText}</span>)}
  </>
);
