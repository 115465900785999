import React, { useEffect } from 'react';
import '../styles/main.css';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { EducationEnum, LanguagesEnum, OccupationEnum } from '../services/enums';
import ComboboxField from '../components/ComboboxField';
import SelectField from '../components/SelectField';
import { InputGroup, InputHelpAndErrors, Label } from '../components/utils/forms';
import { UserProfile } from '../utils/models';
import { useNavigate } from 'react-router-dom';
import { AppPages } from '../utils/pages';
import {
  getUserProfileFromLocalStorage,
  saveUserProfileToLocalStorage,
} from '../services/userProfile';
import { GliteButton } from '../components/GliteButton';

interface IFormInput {
  age_group: number;
  native_language: LanguagesEnum;
  education_level: EducationEnum;
  occupation: OccupationEnum;
}

export default function UserInfoForm() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>();

  useEffect(() => {
    // Load user profile from local storage and populate the form
    const userProfile = getUserProfileFromLocalStorage();
    const fields: (keyof UserProfile)[] = [
      'age_group',
      'native_language',
      'education_level',
      'occupation',
    ];

    fields.forEach((field) => {
      if (userProfile[field]) {
        setValue(field as keyof IFormInput, userProfile[field] as never);
      }
    });
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    // TODO: Create a generic userProfile sync function
    const userProfile = getUserProfileFromLocalStorage();
    userProfile.age_group = data.age_group;
    userProfile.native_language = data.native_language;
    userProfile.education_level = data.education_level;
    userProfile.occupation = data.occupation;

    if (userProfile.native_language === LanguagesEnum.english) {
      userProfile.cefr_test_2_years = false;
      userProfile.cefr_level_2_years = null;
    }

    saveUserProfileToLocalStorage(userProfile);

    if (userProfile.native_language === LanguagesEnum.english) {
      navigate(AppPages.VocabInstructions.url);
    } else {
      navigate(AppPages.VocabCEFR.url);
    }
  };

  return (
    <main>
      <div className="container">
        <h1 className="title-lg">Let&apos;s get started!</h1>

        <p>This information will help us to provide more accurate results.</p>

        <form onSubmit={handleSubmit(onSubmit)} className={'mt-2'} id="userInfoForm">
          <InputGroup errors={errors.age_group}>
            <Label label={'Birth year'} required={true} htmlFor={'name'} />

            <input
              {...register('age_group', {
                required: 'This field is required',
                min: {
                  value: new Date().getFullYear() - 100,
                  message: 'Enter a valid year',
                },
                max: {
                  value: new Date().getFullYear() - 10,
                  message: 'Enter a valid year',
                },
              })}
              type="number"
              className="input-field"
              placeholder="Enter a year"
              required={true}
              autoComplete="bday-year"
              aria-invalid={errors.age_group ? 'true' : 'false'}
            />

            <InputHelpAndErrors errors={errors.age_group} />
          </InputGroup>

          <Controller
            render={({ field }) => (
              <ComboboxField
                label="Native language"
                options={LanguagesEnum}
                placeholder="Select native language"
                required={true}
                errors={errors.native_language}
                {...field}
                autoComplete="language"
              />
            )}
            control={control}
            name="native_language"
            rules={{
              required: 'This field is required',
              validate: (v: string) =>
                Object.values(LanguagesEnum).includes(v as LanguagesEnum) ||
                'Select a valid language',
            }}
          />

          <Controller
            render={({ field }) => (
              <SelectField
                label={'Education'}
                options={EducationEnum}
                placeholder={'Select education level'}
                errors={errors.education_level}
                {...field}
              />
            )}
            control={control}
            name="education_level"
            rules={{
              validate: (v: string) =>
                !v ||
                Object.values(EducationEnum).includes(v as EducationEnum) ||
                'Select a valid education level',
            }}
          />

          <Controller
            render={({ field }) => (
              <SelectField
                label={'Occupation'}
                options={OccupationEnum}
                placeholder={'Select occupation'}
                errors={errors.occupation}
                {...field}
              />
            )}
            control={control}
            name="occupation"
            rules={{
              validate: (v: string) =>
                !v ||
                Object.values(OccupationEnum).includes(v as OccupationEnum) ||
                'Select a valid occupation',
            }}
          />

          <GliteButton type="submit" className="mt-2">
            Continue
          </GliteButton>
        </form>
      </div>
    </main>
  );
}
