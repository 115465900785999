import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/main.css';
import { AppPages } from '../utils/pages';
import { useNavigate } from 'react-router-dom';

export default function VocabTestErrorScreen() {
  const [seconds, setSeconds] = React.useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      navigate(AppPages.Home.url);
    }
  }, [seconds]);

  return (
    <main>
      <div className="container">
        <h1 className="title-lg">Oops!</h1>

        <p>
          It looks like something went wrong - perhaps you tried to start the test without
          filling in your details, or maybe we had a technical issue.
        </p>

        <p>
          You will be redirected to the home page in <strong>{seconds}</strong> seconds.
          Sorry about that!
        </p>

        <Link to={AppPages.Home.url} className="btn">
          Go back to the home page
        </Link>
      </div>
    </main>
  );
}
