import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css';
import { AppPages } from '../utils/pages';
import numberWithCommas from '../utils/number';
import { useVocabTestState } from '../services/VocabTestProvider';
import {
  getMostRecentVocabTest,
  saveVocabTestToLocalStorage,
  useVocabStage,
  validateVocabTest,
} from '../services/vocabTest';
import { getUserProfileFromLocalStorage } from '../services/userProfile';

const NATIVE_LEMMA_COUNT = 37500;

interface VocabTestResultData {
  lemmas_number: number;
  level: string;
  native_comparison_pct: number;
  seconds_per_word: number;
  email_provided?: boolean;
}

export default function VocabTestResultsScreen() {
  const [vocabTestResultData, setVocabTestResultData] = useState<VocabTestResultData>();
  const { vocabTest } = useVocabTestState();
  const navigate = useNavigate();
  const userProfile = getUserProfileFromLocalStorage();
  useVocabStage(AppPages.VocabResults, userProfile, vocabTest);

  useEffect(() => {
    let lastVocabTest = vocabTest;
    if (validateVocabTest(lastVocabTest)) {
      saveVocabTestToLocalStorage(lastVocabTest!);
    } else {
      lastVocabTest = getMostRecentVocabTest();
    }

    if (!validateVocabTest(lastVocabTest)) return;
    // Save the result data to local state so we can clear
    // the vocab test state for the next test
    setVocabTestResultData({
      lemmas_number: lastVocabTest!.vocab_size!.lemmas_number,
      level: lastVocabTest!.vocab_size!.level,
      native_comparison_pct: Math.round(
        (lastVocabTest!.vocab_size!.lemmas_number / NATIVE_LEMMA_COUNT) * 100,
      ),
      seconds_per_word: Math.round(
        lastVocabTest!.words.reduce((acc, word) => acc + word.decision_time!, 0) /
          lastVocabTest!.words.length /
          1000,
      ),
      email_provided: !!lastVocabTest!.user_profile!.email,
    });
  }, []);

  return !vocabTestResultData ? null : (
    <main>
      <div className="container">
        <h1 className="title-lg">Your results</h1>

        <div className="inner-container my-4">
          <p>
            Thank you for taking the vocabulary test!{' '}
            {vocabTestResultData.email_provided &&
              'You will receive an email with your results within a few minutes.'}
          </p>

          <div className="inner-container gap-6 mt-4">
            <div className="result-card">
              <h4 className="result-title">Your level</h4>
              <div className="result-statistic">{vocabTestResultData.level}</div>
              <div className="result-explanation">
                <p>There are six CEFR levels: A1, A2, B1, B2, C1, and C2.</p>
                <p>A1 is the lowest level of proficiency, and C2 is the highest.</p>
              </div>
            </div>

            <div className="result-card">
              <h4 className="result-title">Vocabulary size</h4>
              <div className="result-statistic">
                {numberWithCommas(vocabTestResultData.lemmas_number)}{' '}
                <small>lemmas</small>
              </div>
              <div className="result-explanation">
                <p>
                  Based on your answers, we have calculated that you know approximately
                  this many lemmas.
                </p>
                <p>
                  A lemma is all forms of a word combined - for example &apos;cat&apos;
                  and &apos;cats&apos; are the same lemma.
                </p>
              </div>
            </div>

            <div className="result-card">
              <h4 className="result-title">Compared to an average native speaker</h4>
              <div className="result-statistic">
                {vocabTestResultData.native_comparison_pct}%
              </div>
              <div className="result-explanation">
                <p>
                  An average native speaker knows around{' '}
                  {numberWithCommas(NATIVE_LEMMA_COUNT)} lemmas.
                </p>
              </div>
            </div>
          </div>
        </div>

        <h2 className="title-lg mt-1">Get Your Personal English Learning Program</h2>

        <a href="https://glite.ai/" className="btn mt-1">
          Learn More
        </a>
      </div>
    </main>
  );
}
