import React, { createContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import UserInfoForm from './pages/UserInfoForm';
import UserEmailForm from './pages/UserEmailForm';
import VocabTestScreen from './pages/VocabTestScreen';

import CEFRLevelForm from './pages/CEFRLevelForm';
import VocabTestResultsScreen from './pages/VocabTestResultsScreen';
import VocabTestErrorScreen from './pages/VocabTestErrorScreen';
import VocabTestProvider from './services/VocabTestProvider';
import { VocabTest } from './utils/models';
import { fetchVocabTest } from './services/l2Api';
import VocabTestInstructionsScreen from './pages/VocabTestInstructionsScreen';
import { VOCAB_TEST_VERSION } from './services/vocabTest';

interface VocabTestContextProps {
  vocabTest: VocabTest | null;
  setVocabTest: React.Dispatch<React.SetStateAction<VocabTest | null>>;
}

export const VocabTestContext = createContext<VocabTestContextProps | null>(null);

export default function VocabRoutes() {
  const [vocabTest, setVocabTest] = useState<VocabTest | null>(null);

  useEffect(() => {
    if (vocabTest) {
      return;
    }

    fetchVocabTest().then((test) => {
      test.test_version = VOCAB_TEST_VERSION;
      setVocabTest(test);
    });
  }, [vocabTest]);

  useEffect(() => {
    console.debug('VocabTest updated', vocabTest);
  }, [vocabTest]);

  return (
    <VocabTestProvider vocabTest={vocabTest} setVocabTest={setVocabTest}>
      <Routes>
        <Route path="start" element={<UserInfoForm />} />

        <Route path="cefr/:level?" element={<CEFRLevelForm />} />

        <Route path="instructions/:index?" element={<VocabTestInstructionsScreen />} />

        <Route path="words/:index?" element={<VocabTestScreen />} />

        <Route path="email" element={<UserEmailForm />} />

        <Route path="results" element={<VocabTestResultsScreen />} />

        <Route path="error" element={<VocabTestErrorScreen />} />

        <Route path="*" element={<VocabTestErrorScreen />} />
      </Routes>
    </VocabTestProvider>
  );
}
